import React, { useLayoutEffect, useState } from 'react'

interface Props {
  type: string
  height: string
  paddingX: string
  paddingY: string
  bgColor: string
  textColor: string
  textSize: string
  text: string
  isBold: boolean
  isSm: boolean
}

const Badge = (props: Props) => {
  const [mounted, setMounted] = useState(false)

  useLayoutEffect(() => {
    setMounted(true)
  }, [])

  const style = () => {
    let result = {}
    if (props.isSm) {
      result = { fontSize: '11.5px' }
    }
    return result
  }

  const badge = () => {
    let type = 'rounded'
    if (props.type == 'round') {
      type = 'rounded-full mr-2'
    }
    return (
      <span
        className={`inline-flex items-center justify-center ${props.isBold && 'font-bold'} leading-none ${
          props.height
        } ${props.paddingX} ${props.paddingY} ${props.textColor} ${props.textSize} ${props.bgColor} ${type}`}
        style={style()}
      >
        {props.text}
      </span>
    )
  }
  return <>{mounted ? <>{badge()}</> : <></>}</>
}

Badge.defaultProps = {
  // square
  // round
  type: 'square',
  height: 'h-5',
  paddingX: 'px-1',
  paddingY: 'py-0',
  bgColor: 'bg-red-600',
  textColor: 'text-white',
  textSize: 'text-xs',
  text: 'text',
  isBold: false,
  isSm: false,
}

export default Badge
