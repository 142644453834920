import { get, set } from 'local-storage'

const KEY_NAVIGATION = 'navigation'
const KEY_ACCESS_TOKEN = 'access_token'
const KEY_SEARCH_PARAMS = 'search_params'
const KEY_TOP_TAB = 'top_tab'
const KEY_PREVIOUS_URL = 'previous_url'

export const getNavigation = () => {
  return get<number>(KEY_NAVIGATION)
}

export const setNavigation = (value: number) => {
  set<number>(KEY_NAVIGATION, value)
}

export const getAccessToken = () => {
  const result = get<string>(KEY_ACCESS_TOKEN)
  return result == 'undefined' ? undefined : result
}

export const setAccessToken = (value: string) => {
  set<string>(KEY_ACCESS_TOKEN, value)
}

export const getSearchParams = () => {
  const result = get<any>(KEY_SEARCH_PARAMS) || {}
  return result == 'undefined' ? undefined : result
}

export const setSearchParams = (value) => {
  set<any>(KEY_SEARCH_PARAMS, value)
}

export const getTopTab = () => {
  return get<number>(KEY_TOP_TAB)
}

export const setTopTab = (value: number) => {
  set<number>(KEY_TOP_TAB, value)
}
