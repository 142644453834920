const BREAKPOINTS = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
}

module.exports = {
  purge: ['./src/**/*.{js,ts,jsx,tsx}'],
  breakpoints: BREAKPOINTS,
  darkMode: false, // or 'media' or 'class'
  theme: {
    extend: {
      fontSize: {
        xxs: '0.625rem', // 10px
        xxxs: '0.5rem', // 8px
      },
    },
    screens: {
      sm: { min: `${BREAKPOINTS['sm']}px` },
      md: { min: `${BREAKPOINTS['md']}px` },
      lg: { min: `${BREAKPOINTS['lg']}px` },
      xl: { min: `${BREAKPOINTS['xl']}px` },
    },
  },
  corePlugins: {
    preflight: false,
  },
  variants: {
    extend: {},
  },
  plugins: [],
}
