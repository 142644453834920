import * as React from 'react'
import { Button, Container } from '@mui/material'
// @ts-ignore
import * as styles from 'src/assets/styles/error.module.scss'
import { Home } from '@mui/icons-material'
import { moveTop } from 'src/util/common'
import { AppBarBlankPC, AppBarBlankSP } from 'src/components/molecules/app_bar'
import { useBreakpoint } from 'src/util/breakpoints'

const Error = () => {
  const { isSm } = useBreakpoint('sm')

  return (
    <>
      {isSm ? <AppBarBlankSP /> : <AppBarBlankPC />}
      <Container sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div>
          <div className={styles.headline404}>ページが見つかりませんでした。</div>
          <div className={styles.contents404}>
            <div>指定したURLは存在しません。</div>
          </div>
          <div className={styles.button404}>
            <Button
              variant="outlined"
              size="large"
              component="span"
              color="primary"
              startIcon={<Home />}
              sx={{
                borderRadius: '50px',
                borderWidth: '2px',
                '&:hover': {
                  borderWidth: '2px',
                },
              }}
              onClick={() => {
                if (window.location.pathname == '/') {
                  window.location.reload()
                } else {
                  moveTop()
                }
              }}
            >
              HOMEへ戻る
            </Button>
          </div>
        </div>
        {/* <Footer /> */}
      </Container>
    </>
  )
}

export default Error
