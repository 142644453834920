import React from 'react'
import _ from 'lodash'
import { Box, Stack, Typography } from '@mui/material'
import { navigate } from 'gatsby'
import queryString from 'query-string'
import { setNavigation } from './local_storage'

export const isLoggedIn = (user: any) => !!user && !!user?.apiToken

export const isBlank = (value: any) => {
  if (_.isNumber(value) && value > 0) {
    return false
  }
  return _.isEmpty(value) || !!!_.isNumber(value) || _.isNaN(value)
}

export const getNameInitial = (name: string) => {
  const nameSplit = _.split(name, ' ')
  let firstInitial = ''
  let lastInitial = ''
  const regex = /^[A-Za-z0-9]*$/

  if (nameSplit.length > 1) {
    firstInitial = nameSplit[0] ? nameSplit[0][0] : ''
    lastInitial = nameSplit[1] ? nameSplit[1][0] : ''
  } else {
    return nameSplit[0] ? _.upperCase(nameSplit[0][0]) : '?'
  }

  const isExistFirst = !!firstInitial
  const isExistLast = !!lastInitial
  const isHalfWidthFirst = !!firstInitial.match(regex)
  const isHalfWidthLast = !!lastInitial.match(regex)
  let result = '?'
  if (isExistFirst && isExistLast) {
    if (isHalfWidthFirst && isHalfWidthLast) {
      result = _.upperCase(firstInitial + lastInitial)
    } else {
      result = isHalfWidthLast ? _.upperCase(lastInitial) : lastInitial
    }
  } else if (isExistFirst && !isExistLast) {
    result = isHalfWidthFirst ? _.upperCase(firstInitial) : firstInitial
  } else if (!isExistFirst && isExistLast) {
    result = isHalfWidthLast ? _.upperCase(lastInitial) : lastInitial
  }
  return result
}

export const sleep = (msec) => new Promise((resolve) => setTimeout(resolve, msec))

export const objectArrayGroupBy = (array, keyNameForKey, keyNameForValue) => {
  const result = {}
  array.forEach((obj) => {
    const key = obj[keyNameForKey]
    result[key] = result[key] ? result[key] : []
    result[key].push(obj[keyNameForValue])
  })
  return result
}

export const priceUnitConverter = (price: number, enableStyle = false) => {
  const man = Math.floor(price / 10000)
  const sen = price - man * 10000
  if (enableStyle) {
    const result = (
      <Stack direction="row" justifyContent="center" alignItems="end">
        {man != 0 && (
          <Stack direction="row" alignItems="end">
            <Typography sx={{ fontSize: '18px' }}>{man}</Typography>
            <Typography sx={{ fontSize: '12px', padding: '0 3px 2px 3px' }}>万</Typography>
          </Stack>
        )}
        {sen != 0 && <Typography sx={{ fontSize: '18px' }}>{sen}</Typography>}
        <Typography sx={{ fontSize: '12px', padding: '0 0 2px 3px' }}>円</Typography>
      </Stack>
    )
    return result
  } else {
    return `${man == 0 ? '' : man + '万'}${sen == 0 ? '' : sen}円`
  }
}

export const updateQueryParams = (key, value) => {
  const currentQueryParams = queryString.parse(location.search)
  const newQueryParams = _.merge({}, currentQueryParams, {
    [key]: value,
  })
  const queryParamString = queryString.stringify(newQueryParams)
  if (!!queryParamString) {
    navigate(`?${queryParamString}`, { replace: true })
  }
}

export const deleteQueryParams = (key) => {
  const currentQueryParams = queryString.parse(location.search)
  const result = _.omit(currentQueryParams, key)
  const queryParamString = queryString.stringify(result)
  if (!!queryParamString) {
    navigate(`?${queryParamString}`)
  }
}

export const moveTop = () => {
  setNavigation(1)
  navigate('/', { replace: true })
}

export const moveHost = () => {
  setNavigation(2)
  navigate('/', { replace: true })
}

export const moveChat = () => {
  setNavigation(3)
  navigate('/', { replace: true })
}
