import { Backdrop } from '@mui/material'
import React from 'react'
import { MagnifyingGlass, MutatingDots } from 'react-loader-spinner'

export const CirclingDot = ({ isSm = true }: { isSm?: boolean }) => {
  return (
    <Backdrop
      sx={{ color: '#fff', backgroundColor: '#f5f5f5', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <MutatingDots
        visible={true}
        height="100"
        width="100"
        color="#ff9123"
        secondaryColor="#6600ff"
        radius={isSm ? '12.5' : '15'}
        ariaLabel="mutating-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </Backdrop>
  )
}

// export const MovingSearch = ({ isSm = true }: { isSm?: boolean }) => {
//   return (
//     <MagnifyingGlass
//       visible={true}
//       height="60"
//       width="60"
//       ariaLabel="loading"
//       wrapperStyle={{}}
//       wrapperClass="magnifying-glass-wrapper"
//       glassColor="#ebf6f7"
//       color="#898989"
//     />
//   )
// }
