import React from 'react'
// @ts-ignore
import * as styles from 'src/assets/styles/app_bar.module.scss'
import { Button, IconButton, Link, Typography } from '@mui/material'
import { ArrowBack, Login } from '@mui/icons-material'
import { navigate } from 'gatsby'
import Badge from 'src/components/atoms/badge'
// @ts-ignore
import Logo from 'src/assets/images/logo.png'
import { moveTop } from 'src/util/common'

interface Props {
  title: string
  path?: string | null
  onClickBack?: Function | null
  statusText?: string | null
  statusColor?: string | null
}

export const AppBarPC = (props: Props) => {
  return (
    <>
      <div className={styles.appBarPC}>
        <IconButton
          className={styles.backPC}
          onClick={(e) => {
            e.preventDefault()
            if (!!props.path) {
              navigate(props.path)
            } else if (!!props.onClickBack) {
              props.onClickBack()
            } else {
              navigate(-1)
            }
          }}
        >
          <ArrowBack sx={{ fontSize: 30 }} />
        </IconButton>
        <div className={styles.title}>
          <span className={styles.titleTextPC}>{props.title}</span>
          {!!props.statusText && !!props.statusColor && (
            <span style={{ paddingLeft: '8px', verticalAlign: 'text-bottom' }}>
              <Badge
                text={props.statusText}
                bgColor={props.statusColor}
                textSize="text-base"
                paddingX="px-2"
                height="h-6"
              />
            </span>
          )}
        </div>
        <div className={styles.dummyPC}></div>
      </div>
      <div className={styles.marginPC}></div>
    </>
  )
}

export const AppBarSP = (props: Props) => {
  return (
    <>
      <div className={styles.appBarSP}>
        <IconButton
          className={styles.backSP}
          onClick={(e) => {
            e.preventDefault()
            if (!!props.path) {
              navigate(props.path)
            } else if (!!props.onClickBack) {
              props.onClickBack()
            } else {
              navigate(-1)
            }
          }}
        >
          <ArrowBack sx={{ fontSize: 22 }} />
        </IconButton>
        <div className={styles.title}>
          <span className={styles.titleTextSP}>{props.title}</span>
          {!!props.statusText && !!props.statusColor && (
            <span style={{ paddingLeft: '6px', verticalAlign: 'text-bottom' }}>
              <Badge
                text={props.statusText}
                bgColor={props.statusColor}
                textSize="text-xxs"
                paddingX="px-2"
                height="h-5"
              />
            </span>
          )}
        </div>
        <div className={styles.dummySP}></div>
      </div>
      <div className={styles.marginSP}></div>
    </>
  )
}

export const AppBarBlankPC = ({
  loggedIn = true,
  clickableLogo = true,
}: {
  loggedIn?: boolean
  clickableLogo?: boolean
}) => {
  return (
    <>
      <div className={styles.appBarPC}>
        {clickableLogo ? (
          <Link component="button" underline="none" onClick={() => moveTop()}>
            <img src={Logo} className={styles.logoPC} />
          </Link>
        ) : (
          <img src={Logo} className={styles.logoPC} />
        )}
        <>
          {!loggedIn && (
            <Button
              variant="contained"
              color="error"
              startIcon={<Login />}
              sx={{
                width: '190px',
                height: '40px',
                marginLeft: '8px',
                '.MuiSvgIcon-root': { fontSize: 22 },
              }}
              onClick={() => navigate('/login')}
            >
              <Typography sx={{ fontSize: '14x' }}>ログイン</Typography>
            </Button>
          )}
        </>
      </div>
      <div className={styles.marginPC}></div>
    </>
  )
}

export const AppBarBlankSP = ({
  loggedIn = true,
  clickableLogo = true,
}: {
  loggedIn?: boolean
  clickableLogo?: boolean
}) => {
  return (
    <>
      <div className={styles.appBarSP}>
        {clickableLogo ? (
          <Link component="button" underline="none" onClick={() => moveTop()} disabled={!clickableLogo}>
            <img src={Logo} className={styles.logoSP} />
          </Link>
        ) : (
          <img src={Logo} className={styles.logoSP} />
        )}
        <></>
        <>
          {!loggedIn && (
            <Button
              variant="contained"
              color="error"
              startIcon={<Login />}
              sx={{
                width: '130px',
                height: '26px',
                marginLeft: '8px',
                marginRight: '8px',
                padding: 0,
                '.MuiButton-startIcon': {
                  margin: '0 3px 0 0',
                },
                '.MuiSvgIcon-root': { fontSize: 17, padding: 0, margin: 0 },
              }}
              onClick={() => navigate('/login')}
            >
              <Typography sx={{ fontSize: '11px', marginTop: '4px' }}>ログイン</Typography>
            </Button>
          )}
        </>
      </div>
      <div className={styles.marginSP}></div>
    </>
  )
}

export const AppBarLogoutPC = () => {
  return (
    <>
      <div className={styles.appBarPC}>
        <img src={Logo} alt="ロゴ" className={styles.logoPC} />
      </div>
      <div className={styles.marginPC}></div>
    </>
  )
}

export const AppBarLogoutSP = () => {
  return (
    <>
      <div className={styles.appBarSP}>
        <img src={Logo} alt="ロゴ" className={styles.logoSP} />
      </div>
      <div className={styles.marginSP}></div>
    </>
  )
}
