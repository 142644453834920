import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import logger from 'redux-logger'
import { userSlice } from 'src/ducks/slices/user'
import createWebStorage from 'redux-persist/lib/storage/createWebStorage'

const createNoopStorage = () => {
  return {
    getItem(_key) {
      return Promise.resolve(null)
    },
    setItem(_key, value) {
      return Promise.resolve(value)
    },
    removeItem(_key) {
      return Promise.resolve()
    },
  }
}

const storage = typeof window === 'undefined' ? createNoopStorage() : createWebStorage('local')

const reducers = combineReducers({
  user: userSlice.reducer,
})

const persistConfig = {
  key: 'root',
  storage: storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

const middlewareList = (getDefaultMiddleware: any) => {
  const middlewares = getDefaultMiddleware({
    serializableCheck: false,
  })
  if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger)
  }
  return middlewares
}

export default (preloadedState = {}) => {
  const store = configureStore({
    reducer: persistedReducer,
    preloadedState: preloadedState,
    middleware: middlewareList,
    devTools: process.env.NODE_ENV === 'development',
  })
  const persistor = persistStore(store)
  return { store, persistor }
}
