import * as React from 'react'
import type { GatsbyBrowser } from 'gatsby'
import 'tailwindcss/tailwind.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Provider } from 'react-redux'
import configureStore from './src/ducks/stores'
import './src/assets/styles/globals.scss'
import Authentication from './src/components/templates/authentication'
import { PersistGate } from 'redux-persist/integration/react'
import * as Sentry from '@sentry/gatsby'
import ErrorBoundary from 'src/components/organisms/error_boundary'

Sentry.init({
  dsn: 'https://8351347af75b2b3aab17387d419d34cb@o4507533201965056.ingest.us.sentry.io/4507533353811968',
  enabled: (['production', 'staging'].includes(process.env.NODE_ENV) || process.env.SENTRY_ENABLED || false) as boolean,
  environment: process.env.NODE_ENV,
  tracesSampleRate: 1.0,
  // TODO:
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
})

const { persistor, store } = configureStore()

export const wrapPageElement = ({ element, props }) => {
  return <Authentication props={props} element={element} />
}

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {element}
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  )
}

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => false
