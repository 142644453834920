/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMediaQuery } from 'react-responsive'
import { breakpoints } from '../../tailwind.config'

type BreakpointKey = keyof typeof breakpoints

// usage :
// const { isSm } = useBreakpoint('sm')
// const { isMd } = useBreakpoint('md')
// const { isLg } = useBreakpoint('lg')
// const { isXl } = useBreakpoint('xl')
// return (
//   <div>
//     {isSm && (
//       <div>Content</div>
//     )}
//     {isMd && (
//       <div>Content</div>
//     )}
//   </div>
// );
export const useBreakpoint = <K extends BreakpointKey>(breakpointKey: K) => {
  const bool = useMediaQuery({
    query: `(max-width: ${breakpoints[breakpointKey]}px)`,
    // query: `(min-width: ${breakpoints[breakpointKey]})`,
  })
  // @ts-ignore
  const capitalizedKey = breakpointKey[0].toUpperCase() + breakpointKey.substring(1)
  // @ts-ignore
  type Key = `is${Capitalize<K>}`
  return {
    [`is${capitalizedKey}`]: bool,
  } as Record<Key, boolean>
}
