// extracted by mini-css-extract-plugin
export var appBarPC = "app_bar-module--appBarPC--64ea2";
export var appBarSP = "app_bar-module--appBarSP--8212e";
export var backPC = "app_bar-module--backPC--94e80";
export var backSP = "app_bar-module--backSP--a6ffe";
export var dummyPC = "app_bar-module--dummyPC--0d01d";
export var dummySP = "app_bar-module--dummySP--10b87";
export var logoPC = "app_bar-module--logoPC--fb74f";
export var logoSP = "app_bar-module--logoSP--75cc7";
export var marginPC = "app_bar-module--marginPC--30b3a";
export var marginSP = "app_bar-module--marginSP--2c6de";
export var title = "app_bar-module--title--19632";
export var titleTextPC = "app_bar-module--titleTextPC--4ff2f";
export var titleTextSP = "app_bar-module--titleTextSP--2ba3b";