import { default as axiosBase } from 'axios'

const createPrivateAxios = (apiToken) => {
  return axiosBase.create({
    // baseURL: `${process.env.API_SERVER_HOST_APP}`,
    baseURL: `${process.env.API_SERVER_HOST_WEB}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${apiToken}`,
    },
    responseType: 'json',
  })
}

const createPublicAxios = () => {
  return axiosBase.create({
    baseURL: `${process.env.API_SERVER_HOST_WEB}`,
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'json',
  })
}

export const privateAxios = (apiToken: string) => createPrivateAxios(apiToken)
export const publicAxios = createPublicAxios()
