import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type UserState = {
  user: any
}

export const initialState: UserState = {
  user: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<Object>) => {
      return {
        ...state,
        user: action.payload,
      }
    },
  },
})
